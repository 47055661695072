<template>
  <div class="vx-row p-1 pt-3 pb-3 items-center">
    <div class="vx-col md:w-1/12 text-center orderId">{{ credit.credit }}</div>

    <div class="vx-col md:w-1/12 text-center">{{ credit.price }} $</div>

    <div
      class="vx-col md:w-2/12 text-center companyClick"
      @click="OpenCompany()"
    >
      {{ credit.company }}
    </div>
    <div class="vx-col md:w-2/12 text-center">{{ credit.date }}</div>
    <div class="vx-col md:w-1/12 text-center">{{ credit.paymentMethod }}</div>

    <div class="vx-col md:w-1/12 text-center">
      <div
        class="mx-auto"
        style="width: 16px !important; height: 16px; border-radius: 50%"
        :style="'background-color: ' + GetStatusColor(credit.status)"
      ></div>
    </div>
    <div class="vx-col md:w-1/12 text-center">
      <vs-button
        color="#117FED"
        class="pt-2 pb-2"
        @click="$emit('selectedCompany', credit.company)"
        >History</vs-button
      >
    </div>
    <div class="vx-col md:w-1/12 justify-center flex">
      <vs-chip :color="GetOrderStatusColor(credit.paidStatus)">{{
        credit.paidStatus
      }}</vs-chip>
    </div>

    <div class="vx-col md:w-2/12 text-center">{{ credit.paidDate }}</div>
  </div>
</template>

<script>
export default {
  props: {
    credit: {
      type: Object,
      required: true,
    },
  },
  methods: {
    OpenCompany() {
      this.$router.push({
        name: "nokta_admin_customer",
        query: {
          customer: 1,
        },
      });
    },
    GetOrderStatusColor(status) {
      if (status == "Cancelled") return "#FF9F43";
      if (status == "Paid") return "#28C76F";
      if (status == "Unpaid") return "#EA5455";
      return "#C89E82";
    },
    GetStatusColor(status) {
      if (status == "Approved") return "#28C76F";
      if (status == "Cancelled") return "#EA5455";
      if (status == "Waiting") return "#389CA3";
      return "#C89E82";
    },
  },
};
</script>

<style scoped>
.vx-row .vx-col {
  padding: 0px;
  margin: 0px;
}
.orderId {
  color: #117fed;
}
.companyClick:hover {
  cursor: pointer;
  color: #117fed;
  font-weight: 600;
}
</style>
