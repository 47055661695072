<template>
  <div>
    <div class="BgWhiteBS p-3 mt-2">
      <div class="vx-row filterBy w-full">Filter By</div>
      <div class="vx-row w-full">
        <div class="vx-row titles mt-6">
          <div class="vx-row items-center mr-4">
            Company: <vs-input class="ml-2" />
          </div>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>
        <div class="vx-row titles">
          <div class="vx-row items-center mr-4 mt-6">Date</div>
          <div class="vx-row items-center mr-4">
            <div class="vx-col text-center">
              <div>
                Start <vs-icon icon="date_range" color="dark"> </vs-icon>
              </div>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="fromDate"
                placeholder="dd.mm.yyyy"
                @on-change="onFromChange"
              />
            </div>

            <div class="mt-6">-</div>

            <div class="vx-col text-center">
              <div>End <vs-icon icon="date_range" color="dark"> </vs-icon></div>
              <flat-pickr
                :config="configFromdateTimePicker"
                v-model="toDate"
                placeholder="dd.mm.yyyy"
                @on-change="onToChange"
              />
            </div>
          </div>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>
        <div class="vx-row titles">
          <div class="vx-row items-center mr-4 mt-6">Amount</div>
          <div class="vx-row items-center mr-4">
            <div class="vx-col text-center">
              <div>Min Credit</div>
              <vs-input class="ml-2" style="max-width: 70px" />
            </div>

            <div class="mt-6">-</div>

            <div class="vx-col text-center">
              <div>Max Credit</div>
              <vs-input class="ml-2" style="max-width: 70px" />
            </div>
          </div>
        </div>
        <div class="grayLine ml-2 mr-2 sm:block hidden"></div>

        <div class="vx-row titles mt-8">
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>Approved</vs-checkbox>
          </div>
          <div class="vx-col p-0 m mx-auto">
            <vs-checkbox>Cancelled</vs-checkbox>
          </div>
        </div>

        <div class="vx-row ml-auto">
          <vs-button class="mt-6" type="filled" icon="filter_alt"></vs-button>
        </div>
      </div>
    </div>

    <div
      class="BgWhiteBS vx-row p-1 m-0 mt-4 mb-4 orderBar pt-3 pb-3 items-center"
    >
      <div class="vx-col md:w-1/12 text-center">Credit</div>
      <div class="vx-col md:w-1/12 text-center">Price</div>
      <div class="vx-col md:w-2/12 text-center">Company</div>
      <div class="vx-col md:w-2/12 text-center">Date</div>
      <div class="vx-col md:w-1/12 text-center">Payment Method</div>
      <div class="vx-col md:w-1/12 text-center">Status</div>
      <div class="vx-col md:w-1/12 text-center"></div>
      <div class="vx-col md:w-1/12 text-center">Paid Status</div>
      <div class="vx-col md:w-2/12 text-center">Paid Date</div>
    </div>
    <div class="BgWhiteBS mb-2" v-for="credit in FAKECREDITS" :key="credit.id">
      <credit-row @selectedCompany="ChangeSelectedCompany" :credit="credit" />
    </div>

    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="HandleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import CreditRow from "./AdminCreditRow";

export default {
  data() {
    return {
      totalPage: 1,
      currentPage: 1,
      FAKECREDITS: [
        {
          id: 1,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 2,
          credit: "500",
          price: "100",
          company: "Company 2",
          date: "01.01.2020",
          paymentMethod: "Credit Card",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 3,
          credit: "100",
          price: "100",
          company: "Company 3",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Cancelled",
          paidStatus: "Cancelled",
          paidDate: "01.01.2020",
        },
        {
          id: 4,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 5,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 6,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 7,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 8,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Unpaid",
          paidDate: "01.01.2020",
        },
        {
          id: 9,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 10,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
        {
          id: 11,
          credit: "100",
          price: "100",
          company: "Company 1",
          date: "01.01.2020",
          paymentMethod: "Bank Transfer",
          status: "Approved",
          paidStatus: "Paid",
          paidDate: "01.01.2020",
        },
      ],
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },
    };
  },
  methods: {
    HandleChangePage(page) {
      this.currentPage = page;
      //GET DATA FROM API
    },
    ChangeSelectedCompany(company) {
      this.$emit("ChangeSelectedCompany", company);
    },
    //Date Functions
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  components: {
    flatPickr,
    CreditRow,
  },
};
</script>

<style scoped>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.flatpickr-input {
  max-width: 100px;
}
.vx-row {
  padding: 0px;
  margin: 0px;
}

.titles {
  font-weight: 500;
  color: #434343;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
.grayLine {
  border: 1px solid #dedede;
}
.filterBy {
  color: #434343;
  font-weight: 900;
}
</style>
