<template>
  <div>
    <div class="BgWhiteBS vx-row w-full p-4">
      <div class="w-full mb-4" style="font-size: 1.1rem; font-weight: 500">
        <span @click="$emit('BackToList')" style="cursor: pointer">
          Credits
        </span>
        /
        <span style="font-weight: 600"> {{ selectedCompany }} </span>
      </div>

      <div>Credit 600</div>
    </div>

    <div class="vx-row mt-4">
      <div class="vx-col p-0 pr-2 md:w-1/2">
        <div class="BgWhiteBS h-full">
          <div class="BgWhiteBS flex justify-between p-4">
            <vs-icon
              icon="arrow_downward"
              size="small"
              color="#fff"
              bg="#157FED"
              round
            ></vs-icon>
            <div>
              Total Purchased Credits
              <span style="color: #157fed; font-weight: 600">6800</span>
            </div>
          </div>

          <div
            class="
              BgWhiteBS
              vx-row
              p-1
              m-0
              mt-4
              orderBar
              pt-3
              pb-3
              items-center
            "
          >
            <div class="vx-col md:w-1/4 text-center">Credit</div>
            <div class="vx-col md:w-1/4 text-center">Date</div>
            <div class="vx-col md:w-1/4 text-center">Payment Method</div>
            <div class="vx-col md:w-1/4 text-center">Status</div>
          </div>

          <div class="BgWhiteBS">
            <div
              class="vx-row p-1 pt-3 pb-3 items-center"
              v-for="(received, index) in FAKERECEIVEDCREDIT"
              :key="received.id"
              :style="
                index % 2 == 0
                  ? 'background-color: #f5f5f5'
                  : 'background-color: #fff'
              "
            >
              <div class="vx-col md:w-1/4 text-center orderId">
                {{ received.credit }}
              </div>

              <div class="vx-col md:w-1/4 text-center">{{ received.date }}</div>

              <div class="vx-col md:w-1/4 text-center">
                {{ received.paymentMethod }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                <div
                  class="mx-auto"
                  style="
                    width: 16px !important;
                    height: 16px;
                    border-radius: 50%;
                  "
                  :style="
                    'background-color: ' + GetStatusColor(received.status)
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2">
          <vs-pagination
            :max="7"
            :total="totalPagePurchase"
            v-model="currentPagePurchase"
            @change="HandleChangePagePurchase(currentPagePurchase)"
          />
        </div>
      </div>
      <div class="vx-col p-0 pl-2 md:w-1/2">
        <div class="BgWhiteBS h-full">
          <div class="BgWhiteBS flex justify-between p-4">
            <vs-icon
              icon="arrow_upward"
              size="small"
              color="#fff"
              bg="#F57373"
              round
            ></vs-icon>
            <div>
              Total Used Credits
              <span style="color: #f57373; font-weight: 600">865</span>
            </div>
          </div>

          <div
            class="
              BgWhiteBS
              vx-row
              p-1
              m-0
              mt-4
              orderBar
              pt-3
              pb-3
              items-center
            "
          >
            <div class="vx-col md:w-1/4 text-center">Credit</div>
            <div class="vx-col md:w-1/4 text-center">Orders</div>
            <div class="vx-col md:w-1/4 text-center">Payment Method</div>
            <div class="vx-col md:w-1/4 text-center">Status</div>
          </div>

          <div class="BgWhiteBS">
            <div
              class="vx-row p-1 pt-3 pb-3 items-center"
              v-for="(spent, index) in FAKESPENTCREDIT"
              :key="spent.id"
              :style="
                index % 2 == 0
                  ? 'background-color: #f5f5f5'
                  : 'background-color: #fff'
              "
            >
              <div class="vx-col md:w-1/4 text-center creditSpent">
                -{{ spent.credit }}
              </div>

              <div class="vx-col md:w-1/4 text-center orderId">
                #{{ spent.order }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                {{ spent.date }}
              </div>

              <div class="vx-col md:w-1/4 text-center">
                <div
                  class="mx-auto"
                  style="
                    width: 16px !important;
                    height: 16px;
                    border-radius: 50%;
                  "
                  :style="
                    'background-color: ' + GetStatusColorSpent(spent.status)
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2">
          <vs-pagination
            :max="7"
            :total="totalPageUsed"
            v-model="currentPageUsed"
            @change="HandleChangePageUsed(currentPageUsed)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedCompany: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      totalPagePurchase: 1,
      currentPagePurchase: 1,
      totalPageUsed: 1,
      currentPageUsed: 1,
      FAKERECEIVEDCREDIT: [
        {
          id: 1,
          credit: "600",
          date: "2020-01-01",
          paymentMethod: "Credit Card",
          status: "Paid",
        },
        {
          id: 2,
          credit: "600",
          date: "2020-01-01",
          paymentMethod: "Bank Transfer",
          status: "Canceled",
        },
        {
          id: 3,
          credit: "600",
          date: "2020-01-01",
          paymentMethod: "Credit Card",
          status: "Paid",
        },
        {
          id: 4,
          credit: "600",
          date: "2020-01-01",
          paymentMethod: "Bank Transfer",
          status: "Canceled",
        },
      ],
      FAKESPENTCREDIT: [
        {
          id: 1,
          credit: "42",
          order: 124,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 2,
          credit: "26",
          order: 112,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 3,
          credit: "3",
          order: 96,
          date: "2020-01-01",
          status: "C",
        },
        {
          id: 4,
          credit: "45",
          order: 56,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 5,
          credit: "12",
          order: 43,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 6,
          credit: "12",
          order: 42,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 7,
          credit: "12",
          order: 41,
          date: "2020-01-01",
          status: "C",
        },
        {
          id: 8,
          credit: "12",
          order: 32,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 9,
          credit: "43",
          order: 22,
          date: "2020-01-01",
          status: "R",
        },
        {
          id: 10,
          credit: "23",
          order: 15,
          date: "2020-01-01",
          status: "R",
        },
      ],
    };
  },
  methods: {
    HandleChangePagePurchase(page) {
      this.currentPagePurchase = page;
      //GET PURCHASED CREDITS
    },
    HandleChangePageUsed(page) {
      this.currentPageUsed = page;
      //GET USED CREDITS
    },
    GetStatusColor(status) {
      if (status == "Cancelled") return "#FF9F43";
      if (status == "Paid") return "#28C76F";
      if (status == "Unpaid") return "#EA5455";
      return "#C89E82";
    },
    GetStatusColorSpent(status) {
      if (status == "C") return "#FF9F43";
      if (status == "R") return "#28C76F";
      return "#C89E82";
    },
  },
};
</script>

<style>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #434343;
}
.vx-row,
.vx-col {
  padding: 0px;
  margin: 0px;
}
.orderBar {
  font-weight: 600;
  color: #626262;
}
.orderId {
  color: #117fed;
}
.creditSpent {
  color: #f57373;
}
</style>
