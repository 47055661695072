<template>
  <div>
    <Transition name="fade" mode="out-in">
      <component
        :is="activeComponent"
        :selectedCompany="selectedCompany"
        @ChangeSelectedCompany="ChangeSelectedCompany"
        @BackToList="BackToList"
      ></component>
    </Transition>
  </div>
</template>

<script>
import AdminCreditCustomerDetail from "./components/AdminCreditCustomerDetail.vue";
import adminCreditList from "./components/AdminCreditList.vue";

export default {
  data() {
    return {
      activeComponent: "adminCreditList",
      selectedCompany: null,
    };
  },
  methods: {
    BackToList() {
      this.activeComponent = "adminCreditList";
      this.selectedCompany = null;
    },
    ChangeSelectedCompany(company) {
      this.selectedCompany = company;
      if (company) {
        this.activeComponent = "adminCreditCustomerDetail";
      }
    },
  },
  components: {
    AdminCreditCustomerDetail,
    adminCreditList,
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
